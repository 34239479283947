import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

const BlogList = () => {
  const blogs = [
    {
      id: "embedding-privacy-by-design-in-multimodal-AI-development",
      title: "EDPB Guidelines: Embedding Privacy by Design in Multimodal AI Development",
      category: "Privacy & AI",
      date: "January 18, 2025",
      readTime: "10 min read",
      summary: "Explore how the European Data Protection Board's guidelines shape the future of ethical AI development through privacy-first design principles, pseudonymisation techniques, and responsible data handling practices.",
      imageUrl: "/api/placeholder/800/400"
    },
    // Add more blog posts here
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">



        {/* Blog Posts Grid */}
        <div className="grid gap-12">
          {blogs.map(blog => (
            <article
              key={blog.id}
              className="relative group grid md:grid-cols-3 gap-8 items-start"
            >


              {/* Content */}
              <div className="md:col-span-2 space-y-4">
                <div className="space-y-4">
                  <div className="inline-flex items-center px-3 py-1 bg-[#9DC45F]/10 text-[#7A9F3E] rounded-full text-sm font-medium">
                    {blog.category}
                  </div>

                  <h2 className="text-2xl font-bold text-gray-900 group-hover:text-[#7A9F3E] transition-colors">
                    <Link to={`/blog/${blog.id}`} className="block">
                      {blog.title}
                    </Link>
                  </h2>

                  <p className="text-gray-600 leading-relaxed">
                    {blog.summary}
                  </p>
                </div>

                <div className="flex items-center justify-between pt-4">
                  <div className="flex items-center text-sm text-gray-500">
                    <span>{blog.date}</span>
                    <span className="mx-2">•</span>
                    <span>{blog.readTime}</span>
                  </div>


                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogList;