// prod

 const config = {
   apiBaseUrl: process.env.NODE_ENV === 'production'
     ? 'https://api.pixelprobe.ai'
     : 'http://localhost:8787', // Cloudflare Workers dev port
   googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
 };

 export default config;

// local

//const config = {
//  apiBaseUrl: process.env.NODE_ENV === 'production'
//    ? 'https://api.pixelprobe.ai'
//    : 'http://localhost:8000',  // FastAPI local server
//  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
//};

//export default config;