import React, { useState, useEffect, useRef } from 'react';
import {
  Upload, Lock, RefreshCw, Download, Check,
  X, MousePointer2, Undo2, Redo2, Trash2, ChevronDown, AlertCircle
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import api from './utils/api';
import SecurityBanner from './SecurityBanner';
import AudioUpload from './AudioUpload'


const MediaTypeToggle = ({ activeType, onToggle }) => (
  <div className="flex rounded-lg bg-gray-100 p-1 mb-6">
    <button
      className={`flex-1 px-4 py-2 rounded-md transition-all ${
        activeType === 'video' ? 'bg-white shadow-sm' : 'text-gray-600'
      }`}
      onClick={() => onToggle('video')}
    >
      Video
    </button>
    <button disabled
      className={`flex-1 px-4 py-2 rounded-md transition-all ${
        activeType === 'audio' ? 'bg-white shadow-sm' : 'text-gray-600'
      }`}
      onClick={() => onToggle('audio')}
    >
      Audio
    </button>
  </div>
);

const VideoUpload = () => {
  const [file, setFile] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [status, setStatus] = useState(null);
  const [processedVideoUrl, setProcessedVideoUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [redactionType, setRedactionType] = useState('video');
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedPii, setSelectedPii] = useState(['faces', 'license-plates']);
  const [customQuery, setCustomQuery] = useState('');
  const [queries, setQueries] = useState([]);
  const videoRef = useRef(null);
  const [processingError, setProcessingError] = useState(null);
  const [videoReady, setVideoReady] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [mediaType, setMediaType] = useState('video');



const handleDownload = async () => {
  try {
    const response = await fetch(processedVideoUrl);
    const blob = await response.blob();
    // Get original filename from the file state
    const originalName = file.name;
    const nameWithoutExt = originalName.substring(0, originalName.lastIndexOf('.'));
    const downloadName = `${nameWithoutExt}_redacted.mp4`;

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = downloadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Download failed');
  }
};

const redactionOptions = [
  {
    value: 'video',
    label: 'Video Only',
    description: ''
  },
  {
    value: 'audio',
    label: 'Audio Only (Pro)',
    description: 'Redact sensitive audio information',
    disabled: true
  },
  {
    value: 'both',
    label: 'Video & Audio (Pro)',
    description: 'Complete privacy protection',
    disabled: true
  }
];


    const validateVideo = (file) => {
    return new Promise((resolve, reject) => {
      // File size validation (50MB)
      const maxSize = 50 * 1024 * 1024; // 50MB in bytes

      if (file.size > maxSize) {
        reject("File size must be less than 50MB");
        return;
      }

      // Format validation
      const validFormats = ['video/mp4', 'video/quicktime', 'video/x-msvideo'];
      if (!validFormats.includes(file.type)) {
        reject("Only MP4, MOV, and AVI formats are supported");
        return;
      }

      // Create video element for duration and resolution check
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);

        // Duration validation
        if (video.duration > 60) {
          reject("Video must be less than 1 minute long");
          return;
        }

        // Resolution validation
        if (video.videoWidth > 1920 || video.videoHeight > 1080) {
          reject("Maximum resolution allowed is 1080p (1920x1080)");
          return;
        }

        resolve(true);
      };

      video.onerror = () => {
        reject("Failed to load video. Please ensure it's a valid video file.");
      };

      video.src = URL.createObjectURL(file);
    });
  };


  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    // Reset states
    setValidationError(null);
    setIsValidating(true);
    setFile(null);
    setPreviewUrl(null);

    try {
      // Basic type check
      if (!selectedFile.type.startsWith('video/')) {
        throw new Error("Please select a valid video file");
      }

      // Validate video
      await validateVideo(selectedFile);

      // If validation passes, set file and preview
      setFile(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));

    } catch (error) {
      setValidationError(error.message || error);
    } finally {
      setIsValidating(false);
    }
  };

  const togglePiiType = (type) => {
    setSelectedPii(prev =>
      prev.includes(type)
        ? prev.filter(t => t !== type)
        : [...prev, type]
    );
  };

  const handleQuerySubmit = (e) => {
    e.preventDefault();
    if (customQuery.trim()) {
      setQueries([...queries, customQuery.trim()]);
      setCustomQuery('');
    }
  };

  const removeQuery = (queryToRemove) => {
    setQueries(queries.filter(q => q !== queryToRemove));
  };

useEffect(() => {
  let pollInterval;

  const checkStatus = async () => {
    if (!videoId) return;

    try {
      const { data } = await api.get(`/api/videos/status/${videoId}`);

      if (data.status === 'completed') {
        setStatus('completed');
        setProcessedVideoUrl(data.download_url);
        clearInterval(pollInterval);

        // Start a one-time timeout when status becomes completed
        setTimeout(() => {
          setVideoReady(true);
        }, 120000); // Reduced to 60 seconds for testing

      } else if (data.status === 'failed') {
        setStatus('failed');
        setProcessingError(data.error);
        clearInterval(pollInterval);
      }
    } catch (error) {
      setStatus('failed');
      setProcessingError('Failed to check processing status');
      clearInterval(pollInterval);
    }
  };

  if (status === 'processing' && videoId) {
    setVideoReady(false); // Reset video ready state
    checkStatus();
    pollInterval = setInterval(checkStatus, 3000);
  }

  return () => {
    if (pollInterval) {
      clearInterval(pollInterval);
    }
  };
}, [videoId, status]);

  const handleProcessing = async () => {
  if (!file || status === 'processing' || status === 'pending') return;

  const formData = new FormData();
  formData.append('file', file);

  // Add model selection
  const modelType = selectedPii.includes('license-plates') ?
    'both' : 'face';
  formData.append('models', modelType);

  try {
    setStatus('pending');
    setUploadProgress(0);
    setProcessingError(null);

    const { data } = await api.post('/api/videos/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(progress);
      }
    });

    setVideoId(data.id);
    setStatus('processing');
  } catch (error) {
    setProcessingError(error.response?.data?.detail || 'Failed to upload video');
    setStatus('failed');
  }
};

const DownloadButton = ({ processedVideoUrl }) => {
  const [downloadState, setDownloadState] = useState('idle'); // idle, downloading, success, error

  const handleDownload = async () => {
    if (downloadState === 'downloading') return;

    try {
      setDownloadState('downloading');

      const response = await fetch(processedVideoUrl);
      if (!response.ok) throw new Error('Download failed');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = processedVideoUrl.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setDownloadState('success');
      setTimeout(() => setDownloadState('idle'), 2000); // Reset after 2 seconds
    } catch (error) {
      console.error('Download failed:', error);
      setDownloadState('error');
      setTimeout(() => setDownloadState('idle'), 2000);
    }
  };

  return (
    <button
      onClick={handleDownload}
      disabled={downloadState === 'downloading'}
      className={`px-4 py-2 rounded-lg flex items-center gap-2 transition-all duration-200 ${
        downloadState === 'error'
          ? 'border border-red-500 text-red-500 hover:bg-red-50'
          : downloadState === 'success'
          ? 'border border-[#9DC45F] text-[#9DC45F] bg-[#9DC45F]/10'
          : 'border border-[#9DC45F] text-[#9DC45F] hover:bg-[#9DC45F]/10'
      }`}
    >
      {downloadState === 'downloading' ? (
        <>
          <RefreshCw className="w-4 h-4 animate-spin" />
          Downloading...
        </>
      ) : downloadState === 'success' ? (
        <>
          <Check className="w-4 h-4" />
          Downloaded
        </>
      ) : downloadState === 'error' ? (
        <>
          <Download className="w-4 h-4" />
          Try Again
        </>
      ) : (
        <>
          <Download className="w-4 h-4" />
          Download
        </>
      )}
    </button>
  );
};


return (
    <div className="min-h-screen bg-gradient-to-br from-[#f3f9eb] to-white pt-20">
    <div className="max-w-7xl mx-auto p-6">
  <MediaTypeToggle activeType={mediaType} onToggle={setMediaType} />

      <SecurityBanner />
      {mediaType === 'video' ? (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="space-y-4">

            {/* Redaction Type Section */}
                <div className="bg-white rounded-xl shadow-sm border border-gray-100">
                  <div className="p-6 space-y-4">
                    <div className="space-y-1.5">
                      <label className="text-sm font-semibold text-gray-900">
                        Redaction Type
                      </label>
                      <p className="text-xs text-gray-500">
                        Choose what content to redact from your video
                      </p>
                    </div>

                    <div className="relative">
                      <select
                          value={redactionType}
                          onChange={(e) => setRedactionType(e.target.value)}
                          className="w-full p-3 pr-10 appearance-none bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-[#9DC45F]/20 focus:border-[#9DC45F] focus:outline-none transition-colors text-sm text-gray-900"
                        >
                          {redactionOptions.map(option => (
                            <option
                              key={option.value}
                              value={option.value}
                              disabled={option.disabled}
                              className="text-sm font-normal"
                            >
                              {option.label}
                            </option>
                          ))}
                        </select>
                      <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400 pointer-events-none" />
                    </div>

                    <div className="text-sm text-gray-600">
                      {redactionOptions.find(opt => opt.value === redactionType)?.description}
                    </div>
                  </div>
                </div>

            {/* Video Upload/Preview - Fixed aspect ratio */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
      {validationError && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-700 text-sm">
          <div className="flex items-center gap-2">
            <AlertCircle className="w-4 h-4" />
            {validationError}
          </div>
        </div>
      )}

      {!previewUrl ? (
        <div className="border-2 border-dashed border-gray-200 rounded-lg p-8 text-center hover:border-[#9DC45F] transition-colors aspect-w-16 aspect-h-9">
          <input
            type="file"
            onChange={handleFileChange}
            className="hidden"
            id="video-upload"
            accept="video/mp4,video/quicktime,video/x-msvideo"
          />
          <label htmlFor="video-upload" className="cursor-pointer flex flex-col items-center justify-center h-full">
            {isValidating ? (
              <RefreshCw className="h-12 w-12 text-gray-400 animate-spin" />
            ) : (
              <Upload className="h-12 w-12 text-gray-400" />
            )}
            <p className="mt-2 text-sm text-gray-600">
              {isValidating ? 'Validating video...' : 'Drop video here or click to upload'}
            </p>
            <p className="mt-1 text-xs text-gray-500">
              MP4, MOV, or AVI • Max 50MB • Max 1 minute • Max 1080p
            </p>
          </label>
        </div>
      ) : (
        <div className="relative">
          <div className="aspect-w-16 aspect-h-9 bg-gray-100 rounded-lg overflow-hidden">
            <video
              src={previewUrl}
              controls
              className="w-full h-full object-contain"
            />
          </div>
          <button
            onClick={() => {
              setPreviewUrl(null);
              setFile(null);
              setValidationError(null);
            }}
            className="absolute -top-2 -right-2 p-1 bg-white rounded-full shadow-md hover:bg-gray-50 transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>
      )}
    </div>

            {/* PII Selection and Custom Query */}
           {previewUrl && (
            <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 space-y-6">
            {/* Section Title */}
            <div className="space-y-4">


             <div className="flex items-center justify-between py-2 border-b border-gray-100">
            <div className="flex items-center gap-2">
              <h3 className="text-sm font-semibold text-gray-900">Auto-Detect PII</h3>
              <div className="flex items-center gap-1 px-2 py-0.5 bg-[#9DC45F]/10 rounded-full">

              </div>
            </div>
            <button
              className="relative inline-flex h-6 w-11 items-center rounded-full bg-[#9DC45F] cursor-not-allowed"
              disabled
            >
              <span className="sr-only">Enable auto-detect PII</span>
              <span className="inline-block h-4 w-4 transform rounded-full bg-white transition translate-x-6" />
            </button>
          </div>
        </div>



            {/* PII Tags */}
            <div className="space-y-3">
              <label className="text-xs font-medium text-gray-600">Basic Detection</label>
              <div className="flex flex-wrap gap-2">
                <button
                  onClick={() => togglePiiType('faces')}
                  className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                    selectedPii.includes('faces')
                      ? 'bg-[#9DC45F]/10 text-[#7A9F3E] border border-[#9DC45F]/20'
                      : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-gray-200'
                  }`}
                >
                  <span className="flex items-center gap-2">
                    Faces
                  </span>
                </button>
                <button
                  onClick={() => togglePiiType('license-plates')}
                  className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                    selectedPii.includes('license-plates')
                      ? 'bg-[#9DC45F]/10 text-[#7A9F3E] border border-[#9DC45F]/20'
                      : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-gray-200'
                  }`}
                >
                  License Plates
                </button>
              </div>
            </div>

            {/* Advanced Detection */}
            <div className="space-y-3">
              <label className="text-xs font-medium text-gray-600">Advanced Detection</label>
              <div className="relative">
                <input
                  type="text"
                  disabled
                  placeholder="e.g. 'medical equipment displays', 'name badges'"
                  className="w-full p-3 border border-gray-200 rounded-lg bg-gray-50/50 cursor-not-allowed text-gray-400 placeholder:text-sm"
                />
                <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center gap-2 px-2 py-1 bg-gray-100/80 rounded-md">
                  <Lock className="w-3.5 h-3.5 text-gray-400" />
                  <span className="text-xs font-medium text-gray-400">Pro</span>
                </div>
              </div>
            </div>

            {/* Process Button */}
            <button
                  onClick={handleProcessing}
                  disabled={
                    !file ||
                    ['processing', 'pending'].includes(status) ||
                    selectedPii.length === 0  // Disabled if no detection options are selected
                  }
                  className="w-full bg-[#9DC45F] text-white px-4 py-3 rounded-lg hover:bg-[#8AB54E] transition-all font-medium disabled:opacity-50 disabled:cursor-not-allowed shadow-sm"
                >
                  {['processing', 'pending'].includes(status) ? (
                    <div className="flex items-center justify-center gap-2">
                      <RefreshCw className="w-4 h-4 animate-spin" />
                      Processing Video...
                    </div>
                  ) : selectedPii.length === 0 ? (
                    'Select detection type'  // Show different text when no options selected
                  ) : (
                    'Process Video'
                  )}
                </button>
              </div>
            )}
          </div>

            {/* Right Column */}
            <div className="space-y-4">
            {/* Time Range Controls */}
<div className="bg-white rounded-xl shadow-sm border border-gray-100 h-[173px] relative">
  <div className="absolute top-4 right-4 flex items-center gap-2 px-2 py-1 bg-gray-100/80 rounded-md">
    <Lock className="w-3.5 h-3.5 text-gray-400" />
    <span className="text-xs font-medium text-gray-400">Pro</span>
  </div>
  <div className="h-full flex flex-col justify-center">
    <div className="px-8 w-full space-y-5">
      {/* Start Frame */}
      <div>
        <span className="text-xs text-gray-600">Start Frame</span>
        <div className="relative">
          <input
            type="range"
            min="0"
            max="100"
            value="0"
            disabled
            className="w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-not-allowed disabled:opacity-50"
          />
        </div>
      </div>
      {/* End Frame */}
      <div>
        <span className="text-xs text-gray-600">End Frame</span>
        <div className="relative">
          <input
            type="range"
            min="0"
            max="100"
            value="100"
            disabled
            className="w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-not-allowed disabled:opacity-50"
          />
        </div>
      </div>
    </div>
  </div>
</div>

            {/* Edit Preview Area - Matched height with Video Preview */}
            <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 h-[360px]">
              <div className="aspect-w-16 aspect-h-9 bg-gray-100 rounded-lg overflow-hidden">
                <div className="flex flex-col items-center justify-center text-center p-6 h-full h-[310px] bg-gray-50/80">
                  <div className="flex flex-col items-center gap-4">
                    {/* Pro Badge */}
                    <div className="flex items-center gap-2 px-3 py-1.5 bg-gray-100 rounded-full border border-gray-200">
                      <Lock className="w-4 h-4 text-gray-500" />
                      <span className="text-sm font-medium text-gray-600">Pro</span>
                    </div>

                    {/* Feature Description */}
                    <div className="space-y-3 max-w-sm">
                      <h3 className="text-gray-800 font-medium">Interactive Video Redaction</h3>
                      <p className="text-gray-600 text-sm">
                        Precisely select and redact specific areas in your video with manual controls
                      </p>

                    </div>


                  </div>
                </div>
              </div>
            </div>

              {/* Action Buttons */}
<div className="flex justify-end gap-3">
  <button
    className="px-4 py-2 bg-[#9DC45F] text-white rounded-lg hover:bg-[#8AB54E] transition-all disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
    disabled
  >
    <Lock className="w-4 h-4" />
    Apply Redaction
  </button>
{status === 'completed' && (
  videoReady ? (
    <DownloadButton processedVideoUrl={processedVideoUrl} />
  ) : (
    <button
      disabled
      className="px-4 py-2 border border-[#9DC45F] text-[#9DC45F] rounded-lg flex items-center gap-2 opacity-50 cursor-not-allowed"
    >
      <RefreshCw className="w-4 h-4 animate-spin" />
      Preparing Download...
    </button>
  )
)}
</div>
          </div>
        </div>
        ) : (
    <AudioUpload />
  )}
      </div>
    </div>
  );
};

export default VideoUpload;