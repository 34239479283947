import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { ChevronLeft, Clock, Calendar, ExternalLink } from 'lucide-react';

const GuidelineCard = ({ title, link }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className="block p-4 border border-gray-200 rounded-lg hover:border-[#9DC45F] transition-colors group"
  >
    <div className="flex items-start justify-between">
      <h3 className="text-sm font-medium text-gray-900 group-hover:text-[#7A9F3E] transition-colors pr-4">
        {title}
      </h3>
      <ExternalLink className="w-4 h-4 text-gray-400 group-hover:text-[#7A9F3E] flex-shrink-0" />
    </div>
  </a>
);

const BlogPost = () => {
  const { id } = useParams();
  const [activeSection, setActiveSection] = useState('');

  // Intersection Observer setup remains the same
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { rootMargin: '-80px 0px -50% 0px' }
    );

    document.querySelectorAll('h1, h2, h3').forEach(
      (section) => observer.observe(section)
    );

    return () => observer.disconnect();
  }, []);

 // Rest of the component helpers remain the same
  const getHeaderId = (text) =>
    text.toLowerCase().replace(/[^\w\s-]/g, '').replace(/\s+/g, '-');

  const TableOfContents = ({ content }) => {
    const headers = content.split('\n')
      .filter(line => line.startsWith('#'))
      .map(line => {
        const level = line.match(/^#+/)[0].length;
        const title = line.replace(/^#+\s*/, '');
        const id = getHeaderId(title);
        return { level, title, id };
      });

    const scrollToSection = (id) => {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
      <nav className="hidden lg:block w-64 pl-8 border-l border-gray-100">
        <div className="sticky top-8">
          <h3 className="text-sm font-medium text-gray-400 mb-4">Contents</h3>
          <ul className="space-y-3">
            {headers.map(({ level, title, id }) => (
              <li
                key={id}
                style={{ paddingLeft: `${(level - 1) * 0.75}rem` }}
                className={`text-sm leading-tight transition-colors duration-200
                  ${activeSection === id ? 'text-gray-800' : 'text-gray-400 hover:text-gray-600'}`}
              >
                <button
                  onClick={() => scrollToSection(id)}
                  className="text-left hover:underline decoration-gray-300 underline-offset-2"
                >
                  {title}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    );
  };

  const markdownComponents = {
    // Previous markdown components remain the same
    h1: ({node, children, ...props}) => (
      <h1
        id={getHeaderId(children)}
        className="text-2xl font-semibold text-gray-900 mt-12 mb-6 scroll-mt-24"
        {...props}
      >
        {children}
      </h1>
    ),
    h2: ({node, children, ...props}) => (
      <h2
        id={getHeaderId(children)}
        className="text-xl font-semibold text-gray-800 mt-10 mb-4 scroll-mt-24"
        {...props}
      >
        {children}
      </h2>
    ),
    h3: ({node, children, ...props}) => (
      <h3
        id={getHeaderId(children)}
        className="text-lg font-medium text-gray-800 mt-8 mb-4 scroll-mt-24"
        {...props}
      >
        {children}
      </h3>
    ),
    p: ({node, ...props}) => (
      <p className="text-gray-600 leading-relaxed mb-6 text-[17px]" {...props} />
    ),
    ul: ({node, ...props}) => (
      <ul className="list-disc list-outside ml-6 space-y-2 mb-6 text-gray-600" {...props} />
    ),
    ol: ({node, ...props}) => (
      <ol className="list-decimal list-outside ml-6 space-y-2 mb-6 text-gray-600" {...props} />
    ),
    li: ({node, ...props}) => (
      <li className="pl-2 text-[17px]" {...props} />
    ),
    strong: ({node, ...props}) => (
      <strong className="font-medium text-gray-900" {...props} />
    ),
    a: ({node, ...props}) => (
      <a
        className="text-blue-600 hover:text-blue-800 underline decoration-blue-200 hover:decoration-blue-500 underline-offset-2 transition-colors"
        {...props}
      />
    ),
  };

  const BLOG_POSTS = {
    "embedding-privacy-by-design-in-multimodal-AI-development": {
      title: "EDPB Guidelines: Embedding Privacy by Design in Multimodal AI Development",
      date: "January 18, 2025",
      readTime: "10 min read",
      author: "Shreya Baid",
      content: `

Artificial Intelligence (AI) is revolutionizing industries and redefining possibilities, but it also raises critical questions about privacy and ethical data use. With the vast amounts of personal data processed in AI systems, ensuring compliance with data protection laws has never been more important. The European Data Protection Board (EDPB) provides vital guidelines to address these challenges, offering a roadmap for embedding privacy by design and by default into AI systems.

# 1. Understanding the EDPB Guidelines

## 1.1 Role of EDPB in AI Regulation
EDPB is at the forefront of enforcing GDPR principles in the evolving landscape of AI technologies. Its guidelines provide clarity on balancing innovation with the fundamental rights of individuals. By promoting responsible data handling practices, EDPB ensures that AI advancements align with legal and ethical standards.

## 1.2 Key Concepts from the Guidelines
* **Pseudonymisation and Anonymisation**: Pseudonymisation reduces the risks associated with data processing by ensuring personal identifiers are masked, but the data remains reversible under strict controls. Anonymisation, on the other hand, permanently removes identifiability, rendering GDPR inapplicable if done correctly.

* **Privacy by Design and by Default**: These principles mandate that data protection measures be embedded from the inception of AI projects, ensuring that compliance and risk mitigation are proactive rather than reactive.

# 2. Privacy by Design in AI Development

## 2.1 Ethical Data Collection and Preparation
The development phase of AI begins with data collection and preparation. Adhering to GDPR principles like data minimisation ensures that only the data strictly necessary for the AI's purpose is collected. Transparency is equally critical—users must be informed about how their data will be used and have the option to provide meaningful consent.

## 2.2 Techniques for Privacy Preservation
The EDPB guidelines emphasize:
* **Pseudonymisation**: Transforming data to mask identities while retaining analytical value
* **Synthetic Data Generation**: Creating artificial data based on patterns and features of real datasets to avoid using actual personal data
* **Differential Privacy**: Adding statistical noise to datasets to protect individual data points

## 2.3 Risk Assessment and Mitigation
Conducting a Data Protection Impact Assessment (DPIA) is crucial for identifying and addressing privacy risks. The EDPB recommends evaluating vulnerabilities such as data linkage and unauthorized access. Implementing robust encryption and access controls further strengthens data security.

# 3. Privacy by Design in AI Deployment

## 3.1 Transparency and Accountability
Deployment requires clear communication about how data is processed and decisions are made. Controllers must:
* Maintain detailed documentation of data processing activities
* Ensure AI systems are explainable, allowing users to understand the rationale behind AI-driven decisions

## 3.2 Data Subject Rights in AI Systems
Respecting data subject rights is central to GDPR compliance. AI systems should facilitate:
* **Access and Correction**: Allowing users to review and amend their data
* **Erasure and Portability**: Providing options to delete data or transfer it to other services
* **Consent Management**: Enabling users to withdraw consent without difficulty

# 4. Benefits of Embedding Privacy by Design

## 4.1 Trust and Adoption
Privacy by design fosters trust among users, stakeholders, and regulators. By prioritizing data protection, organizations can enhance their reputation and drive AI adoption.

## 4.2 Legal and Ethical Alignment
Aligning AI systems with GDPR not only ensures legal compliance but also upholds ethical standards. This proactive approach mitigates risks of fines and reputational damage while supporting sustainable innovation.

# 5. Practical Steps for Multimodal AI Systems

## 5.1 Anonymization
* **Definition of Irreversibility**: Anonymization requires the complete removal of identifiability, ensuring that individuals cannot be singled out, linked, or inferred even with additional datasets.
* **Exemption from GDPR**: Once data is anonymized, it no longer qualifies as personal data under GDPR, making its processing outside the GDPR framework lawful.
* **Technical and Organizational Measures**: The EDPB emphasizes that achieving true anonymization involves rigorous safeguards to prevent re-identification risks.

## 5.2 Pseudonymization
* **Retention of Identifiability**: Pseudonymization replaces identifiers with pseudonyms but retains re-linkability through securely stored additional information.
* **Still Personal Data**: Pseudonymized data is still considered personal data under GDPR, requiring compliance with legal bases, transparency, and data protection principles.
* **Risk Mitigation Tool**: Recognized by the EDPB as a safeguard to reduce risks while maintaining data utility, particularly during AI model development and sharing.

## 5.3 Synthetic Data
* **Artificial Data Generation**: Synthetic data replicates the statistical properties of real data without representing actual individuals, ensuring privacy during AI training.
* **Privacy Assurance Assessment**: EDPB highlights the importance of validating that synthetic data does not allow re-identification or retain personal data traces.
* **Supports GDPR Compliance**: Synthetic data facilitates privacy-preserving innovation by reducing dependence on real datasets while enabling lawful processing in AI systems.

`,
    },
  };

  const blog = BLOG_POSTS[id];

  if (!blog) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="text-gray-500">Blog post not found</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Back button */}
        <Link
          to="/blog"
          className="inline-flex items-center text-gray-500 hover:text-gray-800 mb-12 group text-sm"
        >
          <ChevronLeft className="w-4 h-4 mr-1 transition-transform group-hover:-translate-x-0.5" />
          Back to Blog
        </Link>

        <div className="lg:flex lg:gap-16">
          <article className="flex-1">
            {/* Article header */}
            <header className="mb-16">
              <h1 className="text-4xl font-bold text-gray-900 mb-6 leading-tight">
                {blog.title}
              </h1>

              <div className="flex items-center text-sm text-gray-500 space-x-6 mb-8">
                <div className="flex items-center">
                  <Calendar className="w-4 h-4 mr-2" />
                  {blog.date}
                </div>
                <div className="flex items-center">
                  <Clock className="w-4 h-4 mr-2" />
                  {blog.readTime}
                </div>
                {blog.author && (
                  <div className="text-gray-400">by {blog.author}</div>
                )}
              </div>

              {/* Guidelines Cards */}
              <div className="grid sm:grid-cols-2 gap-4 mb-12">
                <GuidelineCard
                  title="EDPB Opinion on processing of personal data in the context of AI"
                  link="https://www.edpb.europa.eu/system/files/2024-12/edpb_opinion_202428_ai-models_en.pdf"
                />
                <GuidelineCard
                  title="EDPB Guidelines on Pseudonymisation"
                  link="https://www.edpb.europa.eu/system/files/2025-01/edpb_guidelines_202501_pseudonymisation_en.pdf"
                />
              </div>
            </header>

            {/* Article content */}
            <div className="prose max-w-none">
              <ReactMarkdown components={markdownComponents}>
                {blog.content}
              </ReactMarkdown>
            </div>
          </article>

          <TableOfContents content={blog.content} />
        </div>
      </div>
    </div>
  );
};

export default BlogPost;