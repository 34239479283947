import React from 'react';
import {
  Fingerprint, UserX, Boxes, LayoutDashboard,
  AudioWaveform, ShieldCheck, Ear, SlidersHorizontal,
  Video, Mic, ScanFace, Speech, AudioLines, Sparkles, FileKey
} from 'lucide-react';

const AdditionalFeatures = () => {
  const FeatureCard = ({ icon: Icon, title, description, isLastCard = false }) => (
    <div className="group relative overflow-hidden rounded-xl bg-white shadow-lg transition-all duration-300 hover:transform hover:-translate-y-1 hover:shadow-xl">
      {/* Decorative elements */}
      <div className="absolute -right-16 -top-16 h-40 w-40 rounded-full bg-[#9DC45F]/10" />
      <div className="absolute -left-16 -bottom-16 h-32 w-32 rounded-full bg-[#9DC45F]/5" />

      <div className="relative h-full p-12">  {/* Changed padding to be consistent */}
  <div className="flex items-center mb-5">  {/* Top row with icon */}
    <Icon className="h-6 w-6 text-[#7A9F3E]" />
  </div>

  <h3 className="text-xl font-semibold text-gray-900 mb-3">{title}</h3>
  <p className="text-sm leading-relaxed text-gray-600">{description}</p>


        {/* Labels at the bottom */}
        <div className="absolute bottom-6 left-6 flex items-center gap-2">
          {!isLastCard && (
            <span className="inline-flex items-center rounded-full bg-[#9DC45F]/10 px-2.5 py-0.5 text-xs font-medium text-[#7A9F3E]">
              API
            </span>
          )}
          <span className="inline-flex items-center rounded-full bg-[#9DC45F]/10 px-2.5 py-0.5 text-xs font-medium text-[#7A9F3E]">
            Playground
          </span>
        </div>
      </div>

      {/* Enhanced hover effect */}
      <div className="absolute bottom-0 left-0 h-1 w-full bg-gradient-to-r from-[#9DC45F]/20 via-[#9DC45F]/60 to-[#9DC45F] scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
    </div>
  );

  const SectionHeader = ({ icon: Icon, title }) => (
    <div className="relative mb-16">
      <div className="flex flex-col items-center text-center space-y-6">
        <div className="relative">
          <div className="absolute inset-0 rounded-full bg-[#9DC45F]/5 blur-xl" />
          <div className="relative rounded-2xl bg-[#9DC45F]/10 p-5">
            <Icon className="h-10 w-10 text-[#7A9F3E]" />
          </div>
        </div>
        <h3 className="text-3xl font-bold text-gray-900">{title}</h3>
      </div>
    </div>
  );

const videoFeatures = [
  {
    icon: UserX,
    title: "Flexible Anonymization",
    description: "Keep your sensitive information private by replacing it with realistic synthetic data. Additional options include smart blurring and pixelation to match your privacy needs.",
    tag: "API"
  },
  {
    icon: Fingerprint,
    title: "Automatic PII Detection",
    description: "Effortlessly protect privacy with our system, which automatically detects and anonymizes faces, license plates, and other sensitive elements in your videos.",
    tag: "API"
  },
  {
    icon: Boxes,
    title: "Video Understanding",
    description: "Ensure comprehensive privacy protection as our smart tracking follows multiple objects throughout the video, even during overlaps or scene changes.",
    tag: "API"
  },
  {
    icon: LayoutDashboard,
    title: "Interactive Editing Studio",
    description: "Simplify video privacy with editing tools to search for objects using natural language queries or select objects across frames, applying changes instantly.",
    tag: "Playground"
  }
];

const audioFeatures = [
  {
    icon: Sparkles,
    title: "Flexible Anonymization",
    description: "Protect sensitive audio content with advanced redaction methods. Features include voice masking, selective muting, and realistic synthetic audio replacement to ensure privacy compliance.",
    tag: "API"
  },
  {
    icon: FileKey,
    title: "Automatic PII Detection",
    description: "Automatically detect and redact sensitive audio content, including names, phone numbers, and other private data, with precision and efficiency.",
    tag: "API"
  },
  {
    icon: Speech,
    title: "Advanced Speech Recognition",
    description: "Enhance privacy features with capabilities like multi-speaker detection, transcription of overlapping speech, and noise filtering to deliver accurate and robust redaction in diverse audio environments.",
    tag: "API"
  },
  {
    icon: AudioLines,
    title: "Interactive Editing Studio",
    description: "Edit sensitive content with intuitive tools, including real-time transcript generation, preview redactions, and timestamp-based fine-tuning controls.",
    tag: "Playground"
  }
];


  return (
    <section id="multimodal-privacy" className="relative overflow-hidden bg-gradient-to-b from-[#9DC45F]/5 to-white">
      <div className="absolute inset-0">
        <div className="absolute -left-1/4 top-0 h-96 w-96 rounded-full bg-[#9DC45F]/10 blur-3xl" />
        <div className="absolute -right-1/4 bottom-0 h-96 w-96 rounded-full bg-[#9DC45F]/10 blur-3xl" />
      </div>

      <div className="relative py-32">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          {/* Main Header */}
          <div className="mb-32 text-center">
            <div className="inline-flex items-center space-x-2 mb-6">
              <span className="h-px w-8 bg-[#9DC45F]" />
              <span className="rounded-full bg-[#9DC45F]/20 px-4 py-2 text-sm font-medium text-[#7A9F3E]">
                Privacy for Multimodal AI
              </span>
              <span className="h-px w-8 bg-[#9DC45F]" />
            </div>
            <h2 className="mb-8 text-4xl font-bold text-gray-900 md:text-5xl lg:text-4xl">
              Enabling Privacy for Your Multimodal AI Development
            </h2>
          </div>

          <div className="space-y-40">
            {/* Video Section */}
            <div>
              <SectionHeader
                icon={Video}
                title="Video Privacy"
              />
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8">
                {videoFeatures.map((feature, index) => (
                  <FeatureCard
                    key={index}
                    {...feature}
                    isLastCard={index === videoFeatures.length - 1}
                  />
                ))}
              </div>
            </div>

            {/* Audio Section */}
            <div>
              <SectionHeader
                icon={Mic}
                title="Audio Privacy"
              />
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8">
                {audioFeatures.map((feature, index) => (
                  <FeatureCard
                    key={index}
                    {...feature}
                    isLastCard={index === audioFeatures.length - 1}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdditionalFeatures;