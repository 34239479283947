import React, { useState, useEffect } from 'react';
import { Upload, RefreshCw, AlertCircle, X, PlayCircle, PauseCircle, Volume2 } from 'lucide-react';
import api from './utils/api';


const AudioUpload = () => {
  const [file, setFile] = useState(null);
  const [audioId, setAudioId] = useState(null);
  const [status, setStatus] = useState(null);
  const [transcription, setTranscription] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [processingError, setProcessingError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const audioRef = React.useRef(null);

  const validateAudio = (file) => {
    return new Promise((resolve, reject) => {
      const maxSize = 50 * 1024 * 1024; // 50MB
      if (file.size > maxSize) {
        reject("File size must be less than 50MB");
        return;
      }

      const validFormats = ['audio/wav', 'audio/mpeg', 'audio/mp4'];
      if (!validFormats.includes(file.type)) {
        reject("Only WAV, MP3, and M4A formats are supported");
        return;
      }

      resolve(true);
    });
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    setValidationError(null);
    setIsValidating(true);
    setFile(null);
    setAudioUrl(null);

    try {
      await validateAudio(selectedFile);
      setFile(selectedFile);
      setAudioUrl(URL.createObjectURL(selectedFile));
    } catch (error) {
      setValidationError(error);
    } finally {
      setIsValidating(false);
    }
  };

  const handleProcessing = async () => {
    if (!file || status === 'processing') return;

    try {
      setStatus('pending');
      setProcessingError(null);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('language', 'en');

      const { data } = await api.post('/api/audio/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setAudioId(data.id);
      setStatus('processing');
    } catch (error) {
      setProcessingError(error.response?.data?.detail || 'Failed to process audio');
      setStatus('failed');
    }
  };

// In AudioUpload component
useEffect(() => {
  let pollInterval;

  const checkStatus = async () => {
    if (!audioId) return;

    try {
      const { data } = await api.get(`/api/audio/status/${audioId}`);
      console.log('Audio status response:', data); // Add logging

      if (data.status === 'completed') {
        setStatus('completed');
        setTranscription(data.transcription);
        clearInterval(pollInterval);
      } else if (data.status === 'failed') {
        setStatus('failed');
        setProcessingError(data.error);
        clearInterval(pollInterval);
      }
    } catch (error) {
      console.error('Status check error:', error); // Add error logging
      setStatus('failed');
      setProcessingError('Failed to check processing status');
      clearInterval(pollInterval);
    }
  };

  if (status === 'processing' && audioId) {
    checkStatus(); // Initial check
    pollInterval = setInterval(checkStatus, 3000);
  }

  return () => {
    if (pollInterval) clearInterval(pollInterval);
  };
}, [audioId, status]);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="space-y-6">


      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        {validationError && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-700 text-sm">
            <div className="flex items-center gap-2">
              <AlertCircle className="w-4 h-4" />
              {validationError}
            </div>
          </div>
        )}

        {!audioUrl ? (
          <div className="border-2 border-dashed border-gray-200 rounded-lg p-8 text-center hover:border-[#9DC45F] transition-colors">
            <input
              type="file"
              onChange={handleFileChange}
              className="hidden"
              id="audio-upload"
              accept=".wav,.mp3,.m4a"
            />
            <label htmlFor="audio-upload" className="cursor-pointer flex flex-col items-center justify-center h-full">
              {isValidating ? (
                <RefreshCw className="h-12 w-12 text-gray-400 animate-spin" />
              ) : (
                <Volume2 className="h-12 w-12 text-gray-400" />
              )}
              <p className="mt-2 text-sm text-gray-600">
                {isValidating ? 'Validating audio...' : 'Drop audio file here or click to upload'}
              </p>
              <p className="mt-1 text-xs text-gray-500">
                WAV, MP3, or M4A • Max 50MB
              </p>
            </label>
          </div>
        ) : (
          <div className="relative space-y-4">
            <div className="bg-gray-50 rounded-lg p-4 flex items-center justify-between">
              <div className="flex items-center gap-3">
                <button
                  onClick={togglePlayPause}
                  className="text-gray-600 hover:text-[#9DC45F] transition-colors"
                >
                  {isPlaying ? (
                    <PauseCircle className="w-8 h-8" />
                  ) : (
                    <PlayCircle className="w-8 h-8" />
                  )}
                </button>
                <span className="text-sm text-gray-600">{file?.name}</span>
              </div>
              <button
                onClick={() => {
                  setAudioUrl(null);
                  setFile(null);
                  setValidationError(null);
                }}
                className="p-1 text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <audio ref={audioRef} src={audioUrl} className="hidden" onEnded={() => setIsPlaying(false)} />
          </div>
        )}

        {file && (
          <button
            onClick={handleProcessing}
            disabled={['processing', 'pending'].includes(status)}
            className="mt-4 w-full bg-[#9DC45F] text-white px-4 py-3 rounded-lg hover:bg-[#8AB54E] transition-all font-medium disabled:opacity-50 disabled:cursor-not-allowed shadow-sm"
          >
            {['processing', 'pending'].includes(status) ? (
              <div className="flex items-center justify-center gap-2">
                <RefreshCw className="w-4 h-4 animate-spin" />
                Processing Audio...
              </div>
            ) : (
              'Process Audio'
            )}
          </button>
        )}
      </div>

      {status === 'completed' && transcription && (
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <h3 className="text-lg font-semibold mb-4">Transcription</h3>
          <div className="prose max-w-none">
            <p className="text-gray-700">{transcription.text}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioUpload;