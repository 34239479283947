import axios from 'axios';
import config from '../config';

const api = axios.create({
  baseURL: config.apiBaseUrl
});

// Audio upload endpoint
const uploadAudio = (formData) => {
  return api.post('/api/audio/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

// Audio status endpoint
const getAudioStatus = (audioId) => {
  return api.get(`/api/audio/status/${audioId}`);
};

// Add these to your exports
export { uploadAudio, getAudioStatus };

api.interceptors.request.use(config => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const decoded = JSON.parse(atob(token.split('.')[1]));
    // Check if token expires in less than 5 minutes
    return decoded.exp * 1000 < Date.now() + (5 * 60 * 1000);
  } catch {
    return true;
  }
};

api.interceptors.request.use(async config => {
  let accessToken = localStorage.getItem('accessToken');

  if (accessToken && isTokenExpired(accessToken)) {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      const response = await axios.post(
        `${config.apiBaseUrl}/api/auth/refresh`,
        { refresh_token: refreshToken },
        { skipAuthRefresh: true }
      );

      const { access_token, refresh_token } = response.data;
      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      accessToken = access_token;
    } catch (error) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.href = '/';
      return Promise.reject(error);
    }
  }

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/api/auth/refresh`,
          {},
          { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }}
        );
        localStorage.setItem('authToken', response.data.access_token);
        originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
        return api(originalRequest);
      } catch {
        localStorage.clear();
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
  }
);

export default api;