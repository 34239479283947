// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, Link } from 'react-router-dom';
import LandingPage from './LandingPage';
import VideoUpload from './VideoUpload';
import OnboardingForm from './OnboardingForm';
import config from './config';
import { Loader2 } from 'lucide-react';
import LogoLoading from './LogoLoading';
import LegalPage from './LegalPage';
import BlogList from './BlogList';
import BlogPost from './BlogPost';

function App() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasOnboarded, setHasOnboarded] = useState(false);
  const [tokenRefreshInterval, setTokenRefreshInterval] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

useEffect(() => {
  const savedToken = localStorage.getItem('authToken');
  const savedOnboarding = localStorage.getItem('hasOnboarded'); // Add this check

  if (savedToken && !isTokenExpired(savedToken)) {
    try {
      const decoded = JSON.parse(atob(savedToken.split('.')[1]));
      setUser(decoded);
      setToken(savedToken);
      setHasOnboarded(savedOnboarding === 'true'); // Fix this line
    } catch (e) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('hasOnboarded');
    }
  } else {
    localStorage.removeItem('authToken');
    localStorage.removeItem('hasOnboarded');
  }
  setLoading(false);
}, []);


  const TOKEN_REFRESH_INTERVAL = 25 * 60 * 1000; // 25 minutes
  const AuthLoading = () => (
  <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50 flex items-center justify-center">
    <div className="bg-white p-8 rounded-xl shadow-2xl max-w-md w-full mx-4 text-center space-y-4">
      <Loader2 className="w-8 h-8 animate-spin mx-auto text-[#9DC45F]" />
      <p className="text-gray-600">Signing you in...</p>
    </div>
  </div>
);

const refreshToken = async () => {
  try {
    const response = await fetch(`${config.apiBaseUrl}/api/auth/refresh`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    const data = await response.json();
    if (response.ok) {
      setToken(data.access_token);
      localStorage.setItem('authToken', data.access_token);
    }
  } catch (error) {
    handleLogout();
  }
};

useEffect(() => {
  if (token) {
    const interval = setInterval(refreshToken, TOKEN_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }
}, [token]);


const handleGoogleSuccess = async (credentialResponse) => {
    setIsAuthenticating(true);
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/auth/google`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: credentialResponse.credential })
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.detail || 'Login failed');

      const authToken = data.access_token || data.token;
      setUser(data.user || data);
      setToken(authToken);
      setHasOnboarded(data.has_onboarded);
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('hasOnboarded', data.has_onboarded);
    } catch (error) {
      alert('Login failed: ' + error.message);
    } finally {
      setIsAuthenticating(false);
    }
  };

const handleLogout = () => {
  setUser(null);
  setToken(null);
  setHasOnboarded(false);
  localStorage.removeItem('authToken');
  localStorage.removeItem('hasOnboarded');
  if (tokenRefreshInterval) {
    clearInterval(tokenRefreshInterval);
  }
};

const isTokenExpired = (token) => {
  try {
    const decoded = JSON.parse(atob(token.split('.')[1]));
    return decoded.exp * 1000 < Date.now();
  } catch {
    return true;
  }
};

// Update token check in useEffect
useEffect(() => {
  const savedToken = localStorage.getItem('authToken');
  if (savedToken && !isTokenExpired(savedToken)) {
    // ... existing token logic
  } else {
    localStorage.removeItem('authToken');
    localStorage.removeItem('hasOnboarded');
  }
  setLoading(false);
}, []);

if (loading) {
  return <LogoLoading />;
}

  return (
  <Router>
    {isAuthenticating && <LogoLoading />}
      {authError && (
        <div className="fixed top-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {authError}
        </div>
      )}
      <div className="min-h-screen bg-gray-50">
        {user && (
  <nav className="bg-white/90 backdrop-blur-sm border-b border-gray-100 fixed w-full z-50">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between h-16 items-center">
        <a href="/" className="h-12">
          <img src="/page-logo.svg" alt="PixelProbe" className="h-full" />
        </a>
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-[#9DC45F] text-white rounded-lg hover:bg-[#8AB54E] transition-all shadow-sm font-medium"
        >
          Logout
        </button>
      </div>
    </div>
  </nav>
)}


        <Routes>
  <Route
    path="/"
    element={
      !user ? (
        <LandingPage
          onGoogleSuccess={handleGoogleSuccess}
          user={user}
          setShowAuthPrompt={setShowAuthPrompt}
        />
      ) : hasOnboarded ? (
        <Navigate to="/playground" replace />
      ) : (
        <Navigate to="/onboarding" replace />
      )
    }
  />
  <Route
    path="/onboarding"
    element={
      user && !hasOnboarded ? (
        <OnboardingForm token={token} setHasOnboarded={setHasOnboarded} />
      ) : user && hasOnboarded ? (
        <Navigate to="/playground" replace />
      ) : (
        <Navigate to="/" replace />
      )
    }
  />
  <Route
    path="/playground"
    element={
      user && hasOnboarded ? (
        <VideoUpload token={token} />
      ) : user && !hasOnboarded ? (
        <Navigate to="/onboarding" replace />
      ) : (
        <Navigate to="/" replace />
      )
    }
  />
  <Route path="/legal" element={<LegalPage />} />
  <Route path="/playground" element={<VideoUpload />} />
  <Route path="/blog" element={<BlogList />} />
  <Route path="/blog/:id" element={<BlogPost />} />
</Routes>
      </div>
    </Router>
  );
}

export default App;